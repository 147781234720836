import * as React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {StaticImage, GatsbyImage} from "gatsby-plugin-image"
import {SectionHero, Section,SectionPageTitle, SectionImageBkg, SectionDescription, Container } from "../components/Section"

const SectionBanner = styled(SectionHero)`
	position:relative;
  height: 100%;
  &:after{
    content: "";
    display:block;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(to right, rgb(19, 131, 193) 0%, rgba(19, 131, 193, 0) 100%);
    width:100%;
    height: 100%;    
  }
`
const HeroCaption = styled.div`	
  position:relative;
  z-index:1;
  width: 100%;
  padding:60px 0 120px;
  @media (min-width:768px){
    padding:70px 0 120px;
  }
  @media (min-width:992px){
    padding:80px 0 120px;
  }
  @media (min-width:1200px){
    padding:90px 0 120px;
  }
  @media (min-width:1600px){
    padding:120px 0 120px;
  }
	
`

const Grid = styled.div`
  margin-bottom:30px;
  
`
const GridItem = styled.div`
  .card-body{
    display:flex;
    flex-wrap:wrap;
    text-align:center;
    @media(min-width:768px){
      text-align:left;
    }
  }
  .card-content{
    position:relative;
    width:100%;
    @media(min-width:768px){
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
      border-right:1px solid #ccc;
    }
  }
  .card-img{
    position:relative;
    width:100%;
    @media(min-width:768px){
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
  }
`

const HorizontalGrid = styled.div`
  display:flex;
  flex-wrap:wrap;
  margin:0 -15px;
`
const HorizontalGridItem = styled.div`
  position:relative;
  width:100%;
  padding:0 15px;
  @media(min-width:768px){
    flex: 0 0 50%;
    max-width: 50%;
  }
  .card{
    @media(min-width:768px){
      height:calc(100% - 30px);
    }
  }
  .card-body{
    flex-direction:column;
    text-align:center;
    padding-bottom:15px;
  }
  .card-content{
    padding-bottom:15px;
  }
`
const CardIcon = styled.div`
  background-color:#fff;
  border-radius:20px;
  box-shadow:0 0 99px rgba(0,0,0,0.16);
  overflow: hidden;
  margin-bottom:30px;
    
`
const CardIconBody = styled.div`
  display:flex;
  flex-wrap:wrap;
  margin:0 -15px;
  p{
    margin-bottom:0;
  }
`
const CardIconFigure = styled.div`
  position:relative;
  width:100%;
  padding:0 15px;
  display:flex;
  align-items:center;
  justify-content: center;
  @media(min-width:768px){
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
`
const CardIconContent = styled.div`
  position:relative;
  width:100%;
  padding:20px 30px;
  @media(min-width:768px){
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    padding:30px;
  }
  @media(min-width:1200px){
    padding:40px;
  }
  @media(min-width:1600px){
    padding:60px;
  }
  > strong{
    text-transform:uppercase;
  }

`
const DoorsWindowsAccessoriesPage = ({ location, data }) => {
  return(
    <Layout location={location}>
      <Seo
        title={data.allContentfulPageData.edges[0].node.metaTitle}
        description={data.allContentfulPageData.edges[0].node.metaDescription.metaDescription}
      />
      <SectionBanner className="section-hero">
        <SectionImageBkg><StaticImage src="../images/door-window-hero.jpg" alt="door-window-hero" /></SectionImageBkg>
        <HeroCaption>
          <Container>
            <SectionPageTitle className="h2 mb-0" color="#fff" textAlign="left" mb="0" ml="0" maxWidth="540px">Doors, Windows, & Accessories</SectionPageTitle>
            <SectionDescription color="#fff" textAlign="left" mb="0" ml="0"><p>Products used on our buildings</p></SectionDescription>
          </Container>
        </HeroCaption>
      </SectionBanner>
      <Section mt="-60px" pt="0" pb="90px" xpb="30px" mpb="10px" bgColor="transparent" className="section-installation">       
        <Container maxWidth="1370px">
          <Grid className="card-grid">
            {
              data.allContentfulAccessories.edges.map((item, i) => {
                if(i < 9) {
                  return(
                    <GridItem className="card-grid-item">
                      <CardIcon className="card">
                        <CardIconBody className="card-body">
                          <CardIconContent className="card-content">    
                            <strong className="h4">{item.node.name}</strong>
                            {item.node.description && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.node.description.childMarkdownRemark.html,
                                }}
                              />
                            )}                       
                          </CardIconContent>
                          <CardIconFigure className="card-img">
                            <GatsbyImage image={item.node.image.gatsbyImageData} alt={item.node.name} />
                          </CardIconFigure>
                        </CardIconBody>
                      </CardIcon>
                    </GridItem>
                  )
                }
              })
            }
          </Grid>
          
          <HorizontalGrid className="card-horizontal">
            {
              data.allContentfulAccessories.edges.map((item, i) => {
                if(i >= 9) {
                  return(
                    <HorizontalGridItem className="card-horizontal-item">
                      <CardIcon className="card">  
                        <CardIconBody className="card-body">
                          <CardIconContent className="card-content">    
                            <strong className="h4">{item.node.name}</strong>   
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.node.description.childMarkdownRemark.html,
                              }}
                            />
                          </CardIconContent>
                          <CardIconFigure className="card-img"><GatsbyImage image={item.node.image.gatsbyImageData} alt={item.node.name} /></CardIconFigure>
                        </CardIconBody>
                      </CardIcon>
                    </HorizontalGridItem>
                  )
                }
              })
            }
          </HorizontalGrid>
        </Container>
      </Section>
    </Layout>
  )

};

export default DoorsWindowsAccessoriesPage;

export const pageQuery = graphql`
  query DoorsWindowsAccessoriesPageQuery{
    allContentfulPageData(filter: {page: {eq: "Doors Windows Accessories"}}) {
      edges {
        node {
          metaTitle
          metaDescription {
            metaDescription
          }
        }
      }
    }
    allContentfulAccessories(sort: {fields: order}) {
      edges {
        node {
          name
          description {
            childMarkdownRemark {
              html
            }
          }
          image {
            gatsbyImageData(quality: 100)
          }
        }
      }
    }
  }
`
